import styles from './404.module.scss';

const NotFoundPage = () => (
  <div className={styles.wrapper}>
    <div className={styles.textContainer}>
      <h1 className={styles.status}>404</h1>
      <h2>This page could not be found.</h2>
    </div>
  </div>
);

export default NotFoundPage;
